import { useAuthStore } from "@/store/auth";
import { useRawCookie } from "@/composables/useRawCookie";

export default defineNuxtRouteMiddleware(() => {
  const auth = useAuthStore();

  const token = useCookie("token").value;
  const refreshToken = useCookie("refresh_token").value;
  const account = useCookie("account").value;
  const nidCookie = useRawCookie("nid").value;

  if (token && refreshToken && account && nidCookie) {
    auth.authenticated = true;
  } else {
    auth.authenticated = false;
    auth.user = null;
  }
});
